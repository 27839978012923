.image-col {
  text-align: center;
  position: relative; }

.demo {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto; }

.description-col {
  display: flex;
  align-items: center; }

@media (max-width: 767px) {
  .devices-screenshot {
    height: 190px; }

  .description-col {
    margin-top: 25px; }

  .frame {
    height: 400px; }

  .demo {
    height: 287px; } }
@media (min-width: 768px) {
  .devices-screenshot-row > div {
    height: 200px; }

  .devices-screenshot {
    height: 200px; }

  .demo-row > div {
    height: 488px; }

  .frame {
    height: 488px; }

  .demo {
    height: 350px; } }
@media (min-width: 992px) {
  .devices-screenshot-row > div {
    height: 300px; }

  .devices-screenshot {
    height: 300px; } }
.container .jumbotron {
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  display: flex;
  align-items: center; }

.container .jumbotron h1 {
  margin-bottom: 1em; }

.container .jumbotron .btn {
  padding-left: 2.5em;
  padding-right: 2.5em; }

#image-background-container {
  position: relative;
  overflow: hidden;
  border-radius: 0;
  background: #111; }

.container .jumbotron, #image-background-container {
  height: calc(100vh - 50px);
  min-height: 250px; }

#jumbotron-content {
  position: relative;
  color: #eee; }

#image-background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0; }

#image-background > .image-tile {
  background-image: url(/covers.jpg);
  animation-name: imageShow;
  animation-duration: 450ms;
  animation-timing-function: ease-out;
  animation-fill-mode: both; }

@media screen and (min-device-pixel-ratio: 2) {
  #image-background > .image-tile {
    background-image: url(covers_2x.jpg); } }
@keyframes imageShow {
  from {
    transform: scale(0.8, 0.8);
    opacity: 0; }
  to {
    transform: scale(1, 1);
    opacity: 0.1; } }
.text-shadow {
  text-shadow: 2px 2px rgba(41, 28, 66, 0.56); }

.media {
  margin-bottom: 25px; }

.media-object {
  font-size: 1.3em; }

.have-you {
  display: inline-block; }

.popup {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  color: black;
  display: flex;
  animation-name: popupShow;
  animation-delay: 1000ms;
  animation-duration: 1000ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transform: translate(0, 100%); }

@keyframes popupShow {
  from {
    transform: translate(0, 100%); }
  to {
    transform: translate(0, 0); } }
.egg {
  width: 60px;
  height: 80px;
  background: blue; }

.stats {
  background: red;
  flex-grow: 1;
  display: flex;
  flex-direction: column; }

.stats-row {
  text-align: center; }

.name {
  float: left; }

.level {
  float: right; }

.progress {
  height: 20px;
  background: white; }

.progress-bar {
  height: 20px;
  width: 30px;
  background: blue; }
